import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import chroma from 'chroma-js'

import { Grid, ArrowRight, Heart, BookOpen } from 'react-feather'
import { randomInt } from '../lib/util'

import Div from '../elements/Div'
import H4 from '../elements/H4'
import P from '../elements/P'
import H5 from '../elements/H5'
import Textarea from '../elements/Textarea'
import Input from '../elements/Input'
import Span from '../elements/Span'
import Flex from '../components/Flex'
import SectionBorder from '../components/SectionBorder'
import Lock from '../components/Lock'
import Layout from '../components/VoteLayout'
import Label from '../components/Label'
import Color from '../components/controls/Color'
import Hidden from '../components/hidden'

import VoteButton from '../components/vote-button'
import SidebarHeading from '../components/heading-sidebar'

import randomDiv from '../lib/generate/bootstrap-colors'
import { db } from '../lib/client'
import { UP_KEYCODE, RIGHT_KEYCODE } from '../lib/constants'

const Main = ({ div }) => {
  const primaryScale = chroma
    .scale(['#000000', div.primary, '#ffffff'])
    .mode('rgb')
    .correctLightness()
    .colors(11)
  const primaryLight = primaryScale[9]
  const primaryLightest = primaryScale[10]
  const primaryDark = primaryScale[3]
  const primaryDarkest = primaryScale[3]
  const primaryBorder = primaryScale[7]

  const secondaryScale = chroma
    .scale(['#000000', div.secondary, '#ffffff'])
    .mode('rgb')
    .correctLightness()
    .colors(11)
  const secondaryLight = secondaryScale[9]
  const secondaryLightest = secondaryScale[10]
  const secondaryDark = secondaryScale[3]
  const secondaryDarkest = secondaryScale[3]
  const secondaryBorder = secondaryScale[7]

  const successScale = chroma
    .scale(['#000000', div.success, '#ffffff'])
    .mode('rgb')
    .correctLightness()
    .colors(11)
  const successLight = successScale[9]
  const successLightest = successScale[10]
  const successDark = successScale[3]
  const successDarkest = successScale[2]
  const successBorder = successScale[7]

  const dangerScale = chroma
    .scale(['#000000', div.danger, '#ffffff'])
    .mode('rgb')
    .correctLightness()
    .colors(11)
  const dangerLight = dangerScale[9]
  const dangerLightest = dangerScale[10]
  const dangerDark = dangerScale[3]
  const dangerDarkest = dangerScale[2]
  const dangerBorder = dangerScale[7]

  const warningScale = chroma
    .scale(['#000000', div.warning, '#ffffff'])
    .mode('rgb')
    .correctLightness()
    .colors(11)
  const warningLight = warningScale[9]
  const warningLightest = warningScale[10]
  const warningDark = warningScale[3]
  const warningDarkest = warningScale[2]
  const warningBorder = warningScale[7]

  const infoScale = chroma
    .scale(['#000000', div.info, '#ffffff'])
    .mode('rgb')
    .correctLightness()
    .colors(11)
  const infoLight = infoScale[9]
  const infoLightest = infoScale[10]
  const infoDark = infoScale[3]
  const infoDarkest = infoScale[2]
  const infoBorder = infoScale[7]

  const lightScale = chroma
    .scale(['#000000', div.light, '#ffffff'])
    .mode('rgb')
    .correctLightness()
    .colors(11)
  const lightLight = lightScale[10]
  const lightLightest = lightScale[11]
  const lightDark = lightScale[3]
  const lightDarkest = lightScale[2]
  const lightBorder = lightScale[9]

  const darkScale = chroma
    .scale(['#000000', div.dark, '#ffffff'])
    .mode('rgb')
    .correctLightness()
    .colors(11)
  const darkLight = darkScale[9]
  const darkLightest = darkScale[10]
  const darkDark = darkScale[3]
  const darkDarkest = darkScale[2]
  const darkBorder = darkScale[7]

  return (
    <div
      css={{
        minHeight: '16rem',
        height: '100%',
        //maxHeight: '100vh',
        overflowY: 'scroll',
        paddingTop: 64,
        paddingLeft: 64,
        paddingRight: 64,
        background: div.canvasBg
      }}
    >
      <div>
        <link
          rel="stylesheet"
          href="https://components-ai.s3.us-east-1.amazonaws.com/assets/bootstrap-css-vars.min.css"
        />
        <style>
          {`:root {` +
            `--primary: ${div.primary};\n` +
            `--primary-light: ${primaryLight};\n` +
            `--primary-lightest: ${primaryLightest};\n` +
            `--primary-dark: ${primaryDark};\n` +
            `--primary-darkest: ${primaryDarkest};\n` +
            `--primary-border: ${primaryBorder};\n` +
            `--secondary: ${div.secondary};\n` +
            `--secondary-light: ${secondaryLight};\n` +
            `--secondary-lightest: ${secondaryLightest};\n` +
            `--secondary-dark: ${secondaryDark};\n` +
            `--secondary-darkest: ${secondaryDarkest};\n` +
            `--secondary-border: ${secondaryBorder};\n` +
            `--info: ${div.info};\n` +
            `--info-light: ${infoLight};\n` +
            `--info-lightest: ${infoLightest};\n` +
            `--info-dark: ${infoDark};\n` +
            `--info-darkest: ${infoDarkest};\n` +
            `--info-border: ${infoBorder};\n` +
            `--success: ${div.success};\n` +
            `--success-light: ${successLight};\n` +
            `--success-lightest: ${successLightest};\n` +
            `--success-dark: ${successDark};\n` +
            `--success-darkest: ${successDarkest};\n` +
            `--success-border: ${successBorder};\n` +
            `--warning: ${div.warning};\n` +
            `--warning-light: ${warningLight};\n` +
            `--warning-lightest: ${warningLightest};\n` +
            `--warning-dark: ${warningDark};\n` +
            `--warning-darkest: ${warningDarkest};\n` +
            `--warning-border: ${warningBorder};\n` +
            `--danger: ${div.danger};\n` +
            `--danger-light: ${dangerLight};\n` +
            `--danger-lightest: ${dangerLightest};\n` +
            `--danger-dark: ${dangerDark};\n` +
            `--danger-darkest: ${dangerDarkest};\n` +
            `--danger-border: ${dangerBorder};\n` +
            `--dark: ${div.dark};\n` +
            `--dark-light: ${darkLight};\n` +
            `--dark-lightest: ${darkLightest};\n` +
            `--dark-dark: ${darkDark};\n` +
            `--dark-darkest: ${darkDarkest};\n` +
            `--dark-border: ${darkBorder};\n` +
            `--light: ${div.light};\n` +
            `--light-light: ${lightLight};\n` +
            `--light-lightest: ${lightLightest};\n` +
            `--light-dark: ${lightDark};\n` +
            `--light-darkest: ${lightDarkest};\n` +
            `--light-border: ${lightBorder};\n` +
            '}'}
        </style>

        <div css={{ width: '100%' }}>
          <section css={{ display: 'flex', width: '100%', marginBottom: 32 }}>
            <div css={{ width: '50%, paddingRight: 16' }}>
              <div className="alert alert-primary" role="alert">
                A simple primary alert—check it out!
              </div>
              <div className="alert alert-secondary" role="alert">
                A simple secondary alert—check it out!
              </div>
              <div className="alert alert-success" role="alert">
                A simple success alert—check it out!
              </div>
              <div className="alert alert-danger" role="alert">
                A simple danger alert—check it out!
              </div>
            </div>
            <div css={{ width: '50%', paddingLeft: 16 }}>
              <div className="alert alert-warning" role="alert">
                A simple warning alert—check it out!
              </div>
              <div className="alert alert-info" role="alert">
                A simple info alert—check it out!
              </div>
              <div className="alert alert-light" role="alert">
                A simple light alert—check it out!
              </div>
              <div className="alert alert-dark" role="alert">
                A simple dark alert—check it out!
              </div>
            </div>
          </section>
          <section css={{ marginBottom: 32 }}>
            <span className="mr-3 badge badge-primary">Primary</span>
            <span className="mr-3 badge badge-secondary">Secondary</span>
            <span className="mr-3 badge badge-success">Success</span>
            <span className="mr-3 badge badge-danger">Danger</span>
            <span className="mr-3 badge badge-warning">Warning</span>
            <span className="mr-3 badge badge-info">Info</span>
            <span className="mr-3 badge badge-light">Light</span>
            <span className="mr-5 badge badge-dark">Dark</span>
            <span className="mr-3 badge badge-pill badge-primary">Primary</span>
            <span className="mr-3 badge badge-pill badge-secondary">
              Secondary
            </span>
            <span className="mr-3 badge badge-pill badge-success">Success</span>
            <span className="mr-3 badge badge-pill badge-danger">Danger</span>
            <span className="mr-3 badge badge-pill badge-warning">Warning</span>
            <span className="mr-3 badge badge-pill badge-info">Info</span>
            <span className="mr-3 badge badge-pill badge-light">Light</span>
            <span className="mr-3 badge badge-pill badge-dark">Dark</span>
          </section>
          <section css={{ marginBottom: 16 }}>
            <button type="button" className="mr-2 btn btn-primary">
              {' '}
              Primary{' '}
            </button>
            <button type="button" className="mr-2 btn btn-secondary">
              {' '}
              Secondary{' '}
            </button>
            <button type="button" className="mr-2 btn btn-success">
              {' '}
              Success{' '}
            </button>
            <button type="button" className="mr-2 btn btn-danger">
              {' '}
              Danger{' '}
            </button>
            <button type="button" className="mr-2 btn btn-warning">
              {' '}
              Warning{' '}
            </button>
            <button type="button" className="mr-2 btn btn-info">
              {' '}
              Info{' '}
            </button>
            <button type="button" className="mr-2 btn btn-light">
              {' '}
              Light{' '}
            </button>
            <button type="button" className="mr-2 btn btn-dark">
              {' '}
              Dark{' '}
            </button>
            <button type="button" className="mr-2 btn btn-link">
              {' '}
              Link{' '}
            </button>
          </section>
          <section css={{ marginBottom: 16 }}>
            <button type="button" className="mr-2 btn btn-outline-primary">
              {' '}
              Primary{' '}
            </button>
            <button type="button" className="mr-2 btn btn-outline-secondary">
              {' '}
              Secondary{' '}
            </button>
            <button type="button" className="mr-2 btn btn-outline-success">
              {' '}
              Success{' '}
            </button>
            <button type="button" className="mr-2 btn btn-outline-danger">
              {' '}
              Danger{' '}
            </button>
            <button type="button" className="mr-2 btn btn-outline-warning">
              {' '}
              Warning{' '}
            </button>
            <button type="button" className="mr-2 btn btn-outline-info">
              {' '}
              Info{' '}
            </button>
            <button type="button" className="mr-2 btn btn-outline-light">
              {' '}
              Light{' '}
            </button>
            <button type="button" className="mr-2 btn btn-outline-dark">
              {' '}
              Dark{' '}
            </button>
          </section>
          <section css={{ marginBottom: 32 }}>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">Library</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Data
                </li>
              </ol>
            </nav>
          </section>
          <section
            css={{
              marginBottom: 32,
              display: 'flex',
              alignItmes: 'flex-start'
            }}
          >
            <div
              className="card text-white bg-primary mx-3"
              style={{ maxWidth: '18rem' }}
            >
              <div className="card-header">Header</div>
              <div className="card-body">
                <h5 className="card-title">Primary card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
            <div
              className="card text-white bg-secondary mx-3"
              style={{ maxWidth: '18rem' }}
            >
              <div className="card-header">Header</div>
              <div className="card-body">
                <h5 className="card-title">Secondary card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
            <div
              className="card text-white bg-success mx-3"
              style={{ maxWidth: '18rem' }}
            >
              <div className="card-header">Header</div>
              <div className="card-body">
                <h5 className="card-title">Success card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
            <div
              className="card text-white bg-danger mx-3"
              style={{ maxWidth: '18rem' }}
            >
              <div className="card-header">Header</div>
              <div className="card-body">
                <h5 className="card-title">Danger card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </section>
          <section
            css={{
              display: 'flex',
              alignItmes: 'flex-start',
              marginBottom: 32
            }}
          >
            <div
              className="card text-white bg-warning mx-3"
              style={{ maxWidth: '18rem' }}
            >
              <div className="card-header">Header</div>
              <div className="card-body">
                <h5 className="card-title">Warning card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
            <div
              className="card text-white bg-info mx-3"
              style={{ maxWidth: '18rem' }}
            >
              <div className="card-header">Header</div>
              <div className="card-body">
                <h5 className="card-title">Info card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
            <div className="card bg-light mx-3" style={{ maxWidth: '18rem' }}>
              <div className="card-header">Header</div>
              <div className="card-body">
                <h5 className="card-title">Light card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
            <div
              className="card text-white bg-dark mx-3"
              style={{ maxWidth: '18rem' }}
            >
              <div className="card-header">Header</div>
              <div className="card-body">
                <h5 className="card-title">Dark card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </section>
          <section
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 32
            }}
          >
            <div css={{ width: '50%', paddingRight: 16 }}>
              <ul className="list-group">
                <li className="list-group-item active">Cras justo odio</li>
                <li className="list-group-item">Dapibus ac facilisis in</li>
                <li className="list-group-item">Morbi leo risus</li>
                <li className="list-group-item">Porta ac consectetur ac</li>
                <li className="list-group-item">Vestibulum at eros</li>
              </ul>
            </div>
            <div css={{ width: '50%', paddingRight: 16 }}>
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Cras justo odio
                  <span className="badge badge-primary badge-pill">14</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Dapibus ac facilisis in
                  <span className="badge badge-primary badge-pill">2</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Morbi leo risus
                  <span className="badge badge-primary badge-pill">1</span>
                </li>
              </ul>
            </div>
          </section>
          <section css={{ marginBottom: 32 }}>
            <form className="needs-validation" novalidate>
              <div className="form-row">
                <div className="col-md-4 mb-3">
                  <label for="validationCustom01">First name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    placeholder="First name"
                    value="Mark"
                    required
                  />
                  <div className="valid-feedback">Looks good!</div>
                </div>
                <div className="col-md-4 mb-3">
                  <label for="validationCustom02">Last name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom02"
                    placeholder="Last name"
                    value="Otto"
                    required
                  />
                  <div className="valid-feedback">Looks good!</div>
                </div>
                <div className="col-md-4 mb-3">
                  <label for="validationCustomUsername">Username</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="inputGroupPrepend">
                        @
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustomUsername"
                      placeholder="Username"
                      aria-describedby="inputGroupPrepend"
                      required
                    />
                    <div className="invalid-feedback">
                      Please choose a username.
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label for="validationCustom03">City</label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom03"
                    placeholder="City"
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide a valid city.
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <label for="validationCustom04">State</label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom04"
                    placeholder="State"
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide a valid state.
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <label for="validationCustom05">Zip</label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom05"
                    placeholder="Zip"
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide a valid zip.
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="invalidCheck"
                    required
                  />
                  <label className="form-check-label" for="invalidCheck">
                    Agree to terms and conditions
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
              </div>
              <button className="btn btn-primary" type="submit">
                Submit form
              </button>
            </form>
          </section>
          <section>
            <nav aria-label="...">
              <ul className="pagination">
                <li className="page-item disabled">
                  <a
                    className="page-link"
                    href="#"
                    tabindex="-1"
                    aria-disabled="true"
                  >
                    Previous
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
                <li className="page-item active" aria-current="page">
                  <a className="page-link" href="#">
                    2 <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </section>
          <section css={{ marginBottom: 32 }}>
            <div className="progress mb-2" style={{ height: 1 }}>
              <div
                class="progress-bar"
                role="progressbar"
                style={{ width: '25%' }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div className="progress mb-2">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: '75%' }}
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div className="progress mb-2">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: '100%' }}
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div className="progress mb-2">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: '25%' }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                25%
              </div>
            </div>
            <div className="progress mb-2">
              <div
                className="progress-bar bg-success"
                role="progressbar"
                style={{ width: '25%' }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div className="progress mb-2">
              <div
                className="progress-bar bg-info"
                role="progressbar"
                style={{ width: '50%' }}
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div className="progress mb-2">
              <div
                className="progress-bar bg-warning"
                role="progressbar"
                style={{ width: '75%' }}
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div className="progress mb-2">
              <div
                className="progress-bar bg-danger"
                role="progressbar"
                style={{ width: '100%' }}
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </section>
          <section className="pb-4">
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-secondary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-danger" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow mr-5 text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-border text-secondary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-border text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-border text-danger" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-border text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-border text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

const GridItem = ({ div, ...props }) => (
  <div
    css={{
      width: '25%',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '16px',
      height: '23vh'
    }}
    {...props}
  >
    <a
      css={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        overflow: 'hidden'
      }}
    >
      <div
        css={{
          filter: `blur(${div.blur}px)`,
          opacity: div.opacity / 100,
          mixBlendMode: div.mixBlendMode,
          backgroundImage: 'url(' + div.backgroundImage + ')',
          backgroundPositionX: div.backgroundPositionX,
          backgroundPositionY: div.backgroundPositionY,
          display: div.display,
          fontWeight: div.fontWeight,
          fontSize: div.fontSize + 'px',
          fontStyle: div.fontStyle,
          alignItems: div.alignItems,
          textAlign: div.textAlign,
          textTransform: div.textTransform,
          backgroundColor: div.bg,
          backgroundSize: div.backgroundSize,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
        }}
      >
        Aa
      </div>
    </a>
  </div>
)

const GridView = ({ pins, onSelect }) => (
  <Flex
    css={{
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      paddingTop: '32px',
      paddingBottom: '32px',
      paddingLeft: '8px',
      paddingRight: '8px'
    }}
  >
    {Array(16)
      .fill(0)
      .map((_, i) => {
        const baseDiv = randomDiv()
        const divWithPins = { ...baseDiv, ...pins }
        const div = {
          ...divWithPins
        }

        return <GridItem key={i} onClick={() => onSelect(div)} div={div} />
      })}
  </Flex>
)

const Sidebar = ({ onChange, value, pins, onPin }) => {
  const changeValue = key => e => {
    onChange(key, e.target.value)
  }

  return (
    <>
      <SidebarHeading>Bootstrap Colors</SidebarHeading>
      <Color
        label="Canvas"
        value={value.canvasBg}
        onChange={changeValue('canvasBg')}
        active={pins.canvasBg}
        onLock={() => onPin('canvasBg', value.canvasBg)}
        width={128}
      />
      <Color
        label="Primary"
        value={value.primary}
        onChange={changeValue('primary')}
        active={pins.primary}
        onLock={() => onPin('primary', value.primary)}
        width={128}
      />
      <Color
        label="Secondary"
        value={value.secondary}
        onChange={changeValue('secondary')}
        active={pins.secondary}
        onLock={() => onPin('secondary', value.secondary)}
        width={128}
      />
      <Color
        label="Success"
        value={value.success}
        onChange={changeValue('success')}
        active={pins.success}
        onLock={() => onPin('success', value.success)}
        width={128}
      />
      <Color
        label="Danger"
        value={value.danger}
        onChange={changeValue('danger')}
        active={pins.danger}
        onLock={() => onPin('danger', value.danger)}
        width={128}
      />
      <Color
        label="Warning"
        value={value.warning}
        onChange={changeValue('warning')}
        active={pins.warning}
        onLock={() => onPin('warning', value.warning)}
        width={128}
      />
      <Color
        label="Info"
        value={value.info}
        onChange={changeValue('info')}
        active={pins.info}
        onLock={() => onPin('info', value.info)}
        width={128}
      />
      <Color
        label="Light"
        value={value.light}
        onChange={changeValue('light')}
        active={pins.light}
        onLock={() => onPin('light', value.light)}
        width={128}
      />
      <Color
        label="Dark"
        value={value.dark}
        onChange={changeValue('dark')}
        active={pins.dark}
        onLock={() => onPin('dark', value.dark)}
        width={128}
      />
      <SectionBorder my={3} />
      <H5 mb={1}>
        <Span fontSize={0}>Sass Variables</Span>
      </H5>
      <Textarea
        bg="transparent"
        height={128}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        fontSize={0}
        readOnly
        value={
          `$primary: ${value.primary};\n` +
          `$secondary: ${value.secondary};\n` +
          `$success: ${value.success};\n` +
          `$danger: ${value.danger};\n` +
          `$warning: ${value.warning};\n` +
          `$info: ${value.info};\n` +
          `$light: ${value.light};\n` +
          `$dark: ${value.dark};\n`
        }
      />
    </>
  )
}

export default ({ initialDiv, initialPins }) => {
  let votes = []
  try {
    votes = JSON.parse(localStorage.getItem('textImageVotes')) || []
  } catch (e) {}

  const [div, setDiv] = useState(initialDiv || randomDiv())
  const [pins, setPins] = useState(initialPins || {})
  const [gridView, setGridView] = useState(false)

  const divWithPins = { ...div, ...pins }
  const fullDiv = {
    ...divWithPins
  }

  const upvote = async () => {
    if (gridView) {
      return
    }

    const { id } = await db
      .collection('components')
      .doc('textImages')
      .collection('votes')
      .add({
        up: true,
        data: fullDiv,
        metadata: { pins }
      })

    localStorage.setItem(
      'textImagesVotes',
      JSON.stringify(
        [
          {
            id
            //backgroundColor: fullGradient.backgroundColor,
            //backgroundImage: fullGradient.backgroundImage
          },
          ...votes
        ]
          .slice(0, 100)
          .filter(Boolean)
      )
    )

    setDiv(randomDiv())
  }
  const skip = () => {
    setDiv(randomDiv())
  }
  const toggleGridView = () => {
    setGridView(!gridView)
  }

  const handleVoteKey = e => {
    if (e.keyCode === UP_KEYCODE || e.keyCode === RIGHT_KEYCODE) {
      e.preventDefault && e.preventDefault()
    } else {
      return
    }

    if (e.keyCode === UP_KEYCODE) {
      upvote()
    } else if (e.keyCode === RIGHT_KEYCODE) {
      skip()
    }
  }

  const handleGridSelection = div => {
    setDiv(div)
    setGridView(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleVoteKey)

    return () => window.removeEventListener('keydown', handleVoteKey)
  })

  return (
    <Layout
      main={
        gridView ? (
          <GridView pins={pins} onSelect={handleGridSelection} />
        ) : (
          <Main div={fullDiv} />
        )
      }
      sidebar={
        <Sidebar
          value={fullDiv}
          pins={pins}
          onChange={(key, value) => {
            setDiv({
              ...div,
              [key]: value
            })

            setPins({
              ...pins,
              [key]: value
            })
          }}
          onPin={(key, value) => {
            if (!pins[key]) {
              setPins({
                ...pins,
                [key]: value
              })
            } else {
              const newPins = { ...pins }
              delete newPins[key]
              setPins(newPins)
            }
          }}
        />
      }
      footer={
        <>
          <div
            css={{
              color: 'black',
              textAlign: 'center',
              //padding: 20,
              display: 'flex',
              width: '100%',
              alignItems: 'center'
              //borderBottom: '1px solid rgba(0,0,0,.1)'
            }}
          >
            {false && (
              <Link
                to="/div/votes"
                css={{
                  color: 'black',
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textDecoration: 'none'
                }}
              >
                <BookOpen size={20} />
                <span css={{ fontSize: 12, paddingLeft: 10 }}>
                  Previous likes
                </span>
              </Link>
            )}
          </div>
          <Flex>
            <VoteButton width="100%" title="Next" onClick={skip}>
              <ArrowRight size={20} />
              <Hidden>Next</Hidden>
            </VoteButton>
          </Flex>
        </>
      }
    />
  )
}

const randomHex = require('random-hex-color')
const chroma = require('chroma-js')
const sample = require('lodash.sample')
const { randomInt } = require('../util')

const generateDiv = color => {
  color = color || randomHex()

  const primary = chroma
    .hsl(randomInt(196, 240), randomInt(64, 100) / 100, randomInt(40, 64) / 100)
    .hex()

  const secondary = chroma
    .hsl(randomInt(196, 240), randomInt(0, 12) / 100, randomInt(40, 60) / 100)
    .hex()
  const success = chroma
    .hsl(randomInt(80, 140), randomInt(64, 100) / 100, randomInt(40, 64) / 100)
    .hex()
  const warning = chroma
    .hsl(randomInt(24, 54), randomInt(80, 100) / 100, randomInt(45, 55) / 100)
    .hex()
  const danger = chroma
    .hsl(randomInt(345, 360), randomInt(54, 100) / 100, randomInt(37, 55) / 100)
    .hex()
  const info = chroma
    .hsl(randomInt(170, 188), randomInt(64, 100) / 100, randomInt(40, 64) / 100)
    .hex()
  const light = chroma
    .hsl(randomInt(196, 204), randomInt(0, 32) / 100, randomInt(74, 96) / 100)
    .hex()
  const dark = chroma
    .hsl(randomInt(196, 204), randomInt(0, 16) / 100, randomInt(0, 20) / 100)
    .hex()

  const div = {
    primary: primary,
    secondary: secondary,
    success: success,
    warning: warning,
    info: info,
    danger: danger,
    light: light,
    dark: dark,
    canvasBg: '#ffffff'
  }

  return {
    ...div
  }
}

module.exports = generateDiv
